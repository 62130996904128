<template>
  <div>
    <CRow class="">
      <CCol
        md="3"
        v-for="(restaurant, index) in allRestaurants"
        v-if="restaurant.is_published_completed"
      >
        <div class="restaurant-card-margin">
          <CCard
            class="min-height-card"
            v-if="restaurant.is_published_completed"
            @click.stop.prevent="reservationHandle(restaurant, 2)"
          >
            <CRow>
              <CCol md="12">
                <CImg
                  class="restaurant-img"
                  :src="restaurant.banner_image_collection[0].url"
                ></CImg>
              </CCol>
              <CCol md="12">
                <CRow class="pl-2 pb-2 pr-2">
                  <CCol md="12" sm="12">
                    <div class="pt-2">
                      <h6 id="traffic" class="card-title mb-0">
                        <b>{{
                          restaurant.restaurant_name | truncate(30, "...")
                        }}</b>
                        <CBadge
                          v-if="restaurant.rating"
                          class="badge-color ml-2 float-right"
                          color="primary"
                          >{{
                            restaurant.rating
                              ? restaurant.rating.toFixed(1)
                              : ""
                          }}
                          <i class="fas fa-star"></i>
                        </CBadge>
                      </h6>
                    </div>
                  </CCol>

                  <CCol md="12" sm="12">
                    <div class="small location-text">
                      <b>{{
                        restaurant.type_of_cuisines | truncate(45, "...")
                      }}</b>
                      <br />
                      {{ restaurant.city.name | truncate(25, "...") }}
                      <b class="location-color">
                        <a class="map-link" @click="mapRedirect(restaurant)"
                          >Show in Map</a
                        >
                      </b>
                    </div>
                  </CCol>
                </CRow>
              </CCol>

              <CCol md="12" sm="12">
                <div class="float-right pr-3">
                  <CButton
                    class="badge-color"
                    color="light"
                    size="sm"
                    variant="outline"
                    @click.stop.prevent="viewMenu(restaurant)"
                    >View Menu</CButton
                  >
                  <CButton
                    class="badge-color ml-2"
                    color="primary"
                    size="sm"
                    @click.stop.prevent="reservationHandle(restaurant, 1)"
                    >Reserve</CButton
                  >
                </div>
              </CCol>
              <!-- <CCol md="12">
                            <div class="p-2">
                                <p class="small pl-2 pr-2 text-muted desc-min-height">
                                    {{ restaurant.description | truncate(200, "...") }}
                                </p>
                            </div>
                            <div class="">
                                <div class="float-right pr-3 pb-3">
                                    <CButton color="primary" @click.stop.prevent="reservationHandle(restaurant)">Reserve</CButton>
                                    <CButton color="primary" @click.stop.prevent="viewMenu(restaurant)" class="ml-3" variant="outline">View Menu</CButton>
                                </div>
                            </div>
                        </CCol> -->
            </CRow>
          </CCard>
        </div>
      </CCol>
    </CRow>

    <CRow>
      <CCol
        v-if="allRestaurantsPublished.length == 0"
        md="12"
        class="text-center"
      >
        <CAlert color="warning"
          >Sorry!!! , We have no any restaurants for this criteria.</CAlert
        >
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "../../store/store";
export default {
  methods: {
    viewMenu(restaurant) {
      this.$router.push({
        path: "/restaurant-menu-view-new/" + restaurant.tenant_id + "",
      });
    },
    reservationHandle(restaurant, type) {
      if (type == 1) {
        this.$router.push({
          path:
            "/restaurant_booking/" +
            restaurant.tenant_id +
            "?redirect_to=reservation",
        });
      } else {
        this.$router.push({
          path: "/restaurant_booking/" + restaurant.tenant_id + "",
        });
      }
    },
    mapRedirect(restaurant) {
      let location = JSON.parse(restaurant.location);
      // this.$router.push({
      //     path: "/pages/map?lat=" + location.lat + "&lng=" + location.lng + "",
      // });
      let route = this.$router.resolve({
        path: "/pages/map?lat=" + location.lat + "&lng=" + location.lng + "",
      });
      window.open(route.href, "_blank");
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {
    allRestaurants() {
      return this.$store.state.allRestaurants;
    },
    allRestaurantsPublished() {
      return this.$store.state.allRestaurants.filter((res) => {
        return res.is_published_completed;
      });
    },
  },
};
</script>

<style scoped>
.desc-min-height {
  min-height: 75px;
}

.restaurant-card-margin {
  margin: 0.4rem;
}

.map-link {
  cursor: pointer;
}

.card-title,
.location-text {
  color: rgb(255, 255, 255);
}

.restaurant-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 1.5rem 1.5rem 0rem 0rem !important;
}

.location-color {
  /* color: blueviolet; */
  color: rgb(189, 119, 255);
}

.min-height-card {
  cursor: pointer;
  min-height: 220px;
  border-radius: 1.5rem !important;
  /* border: 0.0001rem solid #ffffff; */
  background-color: rgba(0, 0, 0, 0.651);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
  min-width: 23vw;
}

.rating-arrangment {
  float: right;
}

@media (max-width: 768px) {
  .rating-arrangment {
    float: none;
  }
}
</style>
