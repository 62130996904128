<template>
<div>
    <loading :active.sync="isLoading" :is-full-page="fullPage" :loader="$store.state.spinerLoader" :color="$store.state.spinerColor"></loading>
    <!-- Start Search restaurent-->
    <div class="bg-image">
        <CRow>
            <CCol class="d-sm-down-none" md="6">
                <div class="heading-margin">
                    <h1 class="heading-font-size">
                        We bring your favourite <br />
                        restaurants at your fingertips<br />
                        <b class="heading-txt-color">Exceptional Dining Experience Starts Here...</b><br />
                    </h1>
                </div>
            </CCol>
            <CCol md="6">
                <CCard class="card-margin">
                    <CRow>
                        <CCol md="12">
                            <div class="m-5">
                                <h1 class="txt-color text-center mb-3 d-sm-down-none">
                                    Search Places
                                </h1>
                                <h1 class="txt-color text-center mb-3 d-md-none">Search</h1>

                                <!-- <CSelect
                    class="pt-5"
                    size="lg"
                    placeholder="Select Location"
                    :options="['Colombo', 'Matara']"
                  ></CSelect> -->

                                <CRow>
                                    <CCol md="12">
                                        <v-select class="mb-4 mt-3" :options="cityListArray" :reduce="(option) => option.id" placeholder="Select Location" v-model="search_city" />
                                    </CCol>
                                </CRow>

                                  <CRow>
                                    <CCol md="12">
                                        <v-select class="mb-4" :options="serviceStationTypeListArray" :reduce="(option) => option.label" placeholder="Select Service Station Type" v-model="search_service_station" />
                                    </CCol>
                                </CRow>


                                <CRow>
                                    <CCol md="12">
                                        <v-select class="mb-4" :options="cuisinesListArray" :reduce="(option) => option.label" placeholder="Select Type Of Cuisines" v-model="search_cuisines_type" />
                                    </CCol>
                                </CRow>

                              
                                <CRow>
                                    <CCol md="12">
                                        <v-select :options="allRestaurantsNameArray" placeholder="Select Place" v-model="search_restaurant" :reduce="(option) => option.label" />
                                    </CCol>
                                </CRow>
                                <CButton @click="searchDropdown()" color="primary mt-5 mb-3 btn-size" size="lg" block>
                                    Search
                                </CButton>
                            </div>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
        </CRow>
    </div>
    <!-- End Search restaurent-->

    <CCard class="remove-mg">
        <div class="rest-bg-imge">
            <div class="resturent-bg-dark">
                <RestaurantCard />
                <CRow v-if="next_url">
                    <CCol class="text-center">
                        <CButton color="primary" variant="outline" class="text-center" @click="loadMore()">Load More</CButton>
                    </CCol>
                </CRow>
            </div>
        </div>
    </CCard>
</div>
</template>

<script>
import RestaurantCard from "@/component/RestaurantCard/RestaurantCard.vue";
import {
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Dashboard",
    components: {
        RestaurantCard,
    },
    data() {
        return {
            cityListArray: [],
            search_city: "",
            search_restaurant: "",
            searchCategory: "",
            isLoading: false,
            fullPage: true,
            allRestaurants: [],
            allRestaurantsNameArray: [],
            cuisinesListArray: [],
            serviceStationTypeListArray: [],
            search_service_station: "",
            search_cuisines_type: "",
            next_url: "",
        };
    },
    methods: {
        ...mapActions("settings", [
            "cityList",
            "cuisineList",
            "serviceStationTypeList",
        ]),
        ...mapActions("restaurant", ["getAllRestaurants"]),
        loadMore() {
            let page = new URL(this.next_url).searchParams.get('page');
            const params = {
                search_city: this.search_city,
                search_restaurant: this.search_restaurant,
                search_service_station: this.search_service_station,
                search_cuisines_type: this.search_cuisines_type,
                page: page
            };

            this.getAllRestaurants(params).then((data) => {
                if (!data.data.error) {
                    this.next_url = data.data.links[data.data.links.length - 1].url;
                    data.data.data.forEach((value, index) => {
                        this.$store.state.allRestaurants.push(value);
                    });
                }
            });
        },
        searchDropdown() {
            this.isLoading = true;

            const params = {
                search_city: this.search_city,
                search_restaurant: this.search_restaurant,
                search_service_station: this.search_service_station,
                search_cuisines_type: this.search_cuisines_type,
                page: 1
            };

            this.getAllRestaurants(params).then((data) => {
                if (!data.data.error) {
                    this.next_url = data.data.links[data.data.links.length - 1].url;
                    this.$store.state.allRestaurants = [];
                    this.$store.state.allRestaurants = data.data.data;
                    this.allRestaurantsNameArray = [];
                    this.$store.state.allRestaurants.forEach((value, index) => {
                        let obj = {};
                        obj.label = value.restaurant_name;
                        obj.id = value.id;
                        this.allRestaurantsNameArray.push(obj);
                    });
                    this.$store.state.allRestaurantsNameArray =
                        this.allRestaurantsNameArray;
                }
                this.isLoading = false;
            });
        },
    },
    mounted() {
        this.cityList().then((data) => {
            if (!data.data.error) {
                this.cityListArray = data.data;
            }
        });

        this.cuisineList().then((data) => {
            if (!data.data.error) {
                this.cuisinesListArray = data.data;
            }
        });

        this.serviceStationTypeList().then((data) => {
            if (!data.data.error) {
                this.serviceStationTypeListArray = data.data;
            }
        });

        const params = {
            page: 1,
        };
        this.getAllRestaurants(params).then((data) => {
            if (!data.data.error) {
                this.next_url = data.data.links[data.data.links.length - 1].url;

                this.$store.state.allRestaurants = [];
                this.$store.state.allRestaurants = data.data.data;
                this.allRestaurantsNameArray = [];
                this.$store.state.allRestaurants.forEach((value, index) => {
                    let obj = {};
                    obj.label = value.restaurant_name;
                    obj.id = value.id;
                    this.allRestaurantsNameArray.push(obj);
                });
                this.$store.state.allRestaurantsNameArray =
                    this.allRestaurantsNameArray;
            }
        });
    },
    computed: {
        restaurantNameArray() {
            return this.$store.state.allRestaurantsNameArray;
        },
    },
};
</script>

<style>
.vs--searchable .vs__dropdown-toggle {
    padding: 8px !important;
    appearance: none;
    color: #ffffff !important;
    border: 1px solid rgb(255 255 255) !important;
}

.vs__search,
.vs__selected {
    color: white !important;
}

.vs__open-indicator {
    fill: rgb(255 255 255) !important;
}
</style>

<style scoped>
.vs--searchable .vs__dropdown-toggle {
    padding: 10px !important;
}

.custom-vselect {
    padding: 12px;
}

.remove-mg {
    margin-bottom: 0 !important;
    border: none !important;
}

.card-margin {
    margin: 5rem !important;
    background-color: rgba(0, 0, 0, 0.822);
}

.rest-bg-imge {
    background-image: url("./../../public/img/bg_images/rest-img.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rest-bg-imge {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.resturent-bg-dark {
    background-color: rgba(0, 0, 0, 0.425);
}

.bg-image {
    background-image: url("./../../public/img/bg_images/bg_image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.heading-margin {
    padding-top: 12vh;
    padding-left: 2vw;
    box-sizing: border-box;
}

.heading-font-size {
    font-size: 3.2rem;
}

.heading-txt-color {
    color: #be70fd;
    font-size: 4rem;
}

.btn-size {
    height: 54px;
}

.txt-color {
    color: rgb(255, 255, 255);
}

@media (max-width: 768px) {
    .card-margin {
        margin: 1rem !important;
        background-color: #00000000;
        border: solid #ffffff00;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .heading-margin {
        padding-top: 0vh;
        padding-left: 4vw;
    }

    .heading-font-size {
        font-size: 2.8rem;
    }

    .heading-txt-color {
        color: #be70fd;
        font-size: 3.5rem;
    }
}
</style>
